import '../scss/app.scss';
import 'unfonts.css';
import Glide from "@glidejs/glide";
import '@glidejs/glide/dist/css/glide.core.css'
import '@glidejs/glide/dist/css/glide.theme.css'
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css'

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import * as _ from '@glidejs/glide/src/utils/wait.js';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

gsap.registerPlugin(ScrollTrigger)

document.addEventListener('DOMContentLoaded', (e) => {
  gsap.defaults({ overwrite: true });


  let menuToggle = document.querySelector('.toggle-menu');
  let navigationContainer = document.querySelector('header nav');
  let header = document.querySelector('header');
  let imageSlider = null;
  let isGridInitialized = false;
  let currentSlideIndexForLightbox = 0;
  let heightOffset = 200;
  const newsWrap = document.querySelector('.news-wrap');
  const singleNewsItems =(newsWrap) ? newsWrap.querySelectorAll('article.k-news-item') : '';

  const modelSelectionLoadMore = document.querySelector('.btn-loadmore');

  document.documentElement.style.setProperty('--headerHeight', header.offsetHeight + 'px');

  menuToggle.addEventListener('click', (e) => {
    navigationContainer.classList.toggle('open');
    menuToggle.classList.toggle('open');
    document.body.classList.toggle('no-scroll');
  })

  if (document.querySelector('.glide-teasers')) {
    new Glide(document.querySelector('.glide-teasers'), {
      type: 'carousel',
      rewind: true,
      gap: 40,
      perView: 1,
      startAt: 0,
      focusAt: 'center',
      peek: {
        before: 10,
        after: window.innerWidth / 2
      },
      breakpoints: {
        1143: {
          gap: 10,
          perView: 2,
          peek: {
            before: 0,
            after: 70
          },
        },
        735: {
          gap: 10,
          perView: 1,
          peek: {
            before: 0,
            after: 70
          },
        }
      }
    }).mount({})
  }

  if (document.querySelector('.glide-images')) {
    imageSlider = new Glide(document.querySelector('.glide-images'), {
      type: 'slider',
      animationDuration: 750,
      autoplay: 3750,
      gap: 1,
      peek: 0,
    })
    imageSlider.on(['mount.after', 'run'], function() {
      currentSlideIndexForLightbox = imageSlider.index
    })
    imageSlider.mount({})
  }

  if (document.querySelector('.glide-models')) {
    new Glide(document.querySelector('.glide-models'), {
      type: 'carousel',
      perView: 4,
      startAt: 1,
      lookAt: 2,
      gap: 40,
      peek: {
        before: -100,
        after: -100
      },
      breakpoints: {
        1280: {
          perView: 3
        },
        768: {
          perView: 3
        },
        600: {
          focusAt: 'center',
          perView: 1,
          lookAt: 1,
          startAt:1,
          peek: {
            before: 50,
            after: 50
          },
        }
      }

    }).mount({})
  }

  if (document.querySelector('.thumb-grid')) {
    const galleryElements = JSON.parse(document.querySelector('.thumb-grid').getAttribute('data-gallery'));
    const lightboxArray = [];

    // PREPARE ITEMS FOR LIGHTBOX
    galleryElements.forEach((element, index) => {
      lightboxArray.push({
        'href': element
      })
    });

    const lightbox = GLightbox({
      elements: lightboxArray,
      touchNavigation: true,
      loop: true,
      autoplayVideos: true
    });

    document.querySelector('.glide__track').addEventListener('click', (e) => {
      lightbox.openAt(currentSlideIndexForLightbox);
    });
    // THUMB LIGHTBOX
    document.querySelector('.thumb-grid').addEventListener('click', (e) => {
      if (e.target.attributes[1].value) {
        lightbox.openAt(e.target.attributes[1].value);
      } else {
        lightbox.open();
      }
    })
  }

  if (document.querySelector('.tab-switcher')) {
    /*modelNavigator();

    htmx.on('htmx:afterSwap', function(e) {
      modelNavigator()
    })*/
  }

  /*if (document.querySelector('details')) {
    // Fetch all the details element.
    const details = document.querySelectorAll("details");

// Add the onclick listeners.
    details.forEach((detail) => {
      detail.addEventListener("toggle", () => {
        if (detail.open) {
          setTargetDetail(detail);
        }
      });
    });

// Close all the details that are not targetDetail.
    function setTargetDetail(targetDetail) {
      details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.open = false;
        }
      });
    }
  }*/

  if (modelSelectionLoadMore) {
    modelSelectionLoadMore.addEventListener('click', (e) => {
    })
  }

  if(document.body.dataset.pageslug === 'ueber-uns' || document.body.dataset.pageslug === 'about') {
    let historyItems = document.querySelectorAll('.history__item');
    historyItems.forEach((item) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: item,
          markers: false,
          start: 'top bottom',
          toggleClass: 'active'
        },

        y: 0
      })
    })
  }

  function newsGrid() {
    if (document.querySelector('.news-wrap') && window.innerWidth > 768) {
      isGridInitialized = true;
      document.documentElement.style.setProperty('--newsWidth', newsWrap.offsetWidth + 'px');
      document.documentElement.style.setProperty('--newsHeight', newsWrap.offsetHeight + heightOffset + 'px');

      singleNewsItems.forEach((newsItem, index) => {
        newsItem.style.setProperty('--newsItemWidth', newsItem.offsetWidth + 'px');
        newsItem.style.setProperty('--newsItemHeight', newsItem.offsetHeight + 'px');

        newsItem.addEventListener('mouseenter', handleMouseOver);
        // newsItem.addEventListener('mouseleave', handleMouseOut);
      })
    }
  }

  function handleScroll() {
    let lastScrollTop = 0;
    const header = document.querySelector('header');

    function isMobile() {
      return window.innerWidth <= 800; // Example for mobile detection
    }

    function onScroll() {
      if (isMobile()) return;

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // Scroll down
        header.style.top = '-100px'; // Adjust based on your header height
      } else {
        // Scroll up
        header.style.top = '0';
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    }

    window.addEventListener('scroll', onScroll);
  }

  function handleMouseOver(e) {
    singleNewsItems.forEach((el) => {
      const elTarget = el.querySelector('div.hover-item');
      elTarget.style.backgroundImage = 'url(' + e.currentTarget.dataset.hover + ')';
    });

    gsap.fromTo('article.k-news-item div.hover-item', {
      opacity: 0, scale: 1.1
    }, {
      opacity: 1, scale: 1, duration: 0.65, stagger: 0.1
    });

    gsap.fromTo('article.k-news-item div.overlay', {
      opacity: 1, y: 0
    }, {
      opacity: 0, y: 150, duration: 0.85, stagger: 0.1
    });

    let el2 = e.currentTarget.querySelector('div.overlay');
    gsap.fromTo(el2, {
      opacity: 0, scale: 1
    }, {
      opacity: 1, scale: 1, duration: 0.65
    });
  }

  function handleMouseOut(e) {
    gsap.fromTo('article.k-news-item div.hover-item', {
      opacity: 1, scale: 1
    }, {
      opacity: 0, scale: 0.8, duration: 0.35, stagger: 0.05
    });

    gsap.fromTo('article.k-news-item div.overlay', {
      opacity: 1, y: 150
    }, {
      opacity: 1, y: 0, duration: 0.85, stagger: 0.1
    });
  }

  function disableGridEffects() {
    isGridInitialized = false;
    singleNewsItems.forEach((newsItem) => {
      newsItem.removeEventListener('mouseenter', handleMouseOver);
      newsItem.removeEventListener('mouseleave', handleMouseOut);
    });
  }

  window.addEventListener('resize', _.throttle(function() {
    if (document.querySelector('.news-wrap') && window.innerWidth > 768) {
      document.documentElement.style.setProperty('--newsWidth', newsWrap.offsetWidth + 'px');
      document.documentElement.style.setProperty('--newsHeight', newsWrap.offsetHeight + heightOffset + 'px');

      singleNewsItems.forEach((newsItem, index) => {
        newsItem.style.setProperty('--newsItemWidth', newsItem.offsetWidth + 'px');
        newsItem.style.setProperty('--newsItemHeight', newsItem.offsetHeight + 'px');
      });

      console.log(isGridInitialized)
      if (window.innerWidth > 768 && !isGridInitialized) {
        newsGrid();
      }

      if (window.innerWidth < 768 && isGridInitialized) {
        disableGridEffects();
      }
    }
  }, 200));

  newsGrid();
  handleScroll();
});

/*
function modelNavigator() {
  let tabSwitcher = document.querySelector('.tab-switcher');
  let tabs = tabSwitcher.querySelectorAll('.tab');
  let tabContent = document.querySelectorAll('.tab-content');

  tabs.forEach((tab) => {
    tab.addEventListener('click', (e) => {
      tabs.forEach((element) => {
        element.classList.remove('active');
      });
      tabContent.forEach((element) => {
        element.classList.remove('active');
      });

      e.currentTarget.classList.add('active');

      tabContent[e.currentTarget.attributes[1].value - 1].classList.add('active');
    })
  })
}
*/
